/* global require */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()

require('turbolinks').start()
// Assign unique ids to emotion-generated styles so turbolinks won't merge them.
document.addEventListener('turbolinks:before-visit', () => {
  document.querySelectorAll('[data-emotion]:not([data-id])').forEach((el) => {
    el.dataset.id = Math.random()
  })
})

// require('@rails/activestorage').start()
// require('channels')

require('./font-awesome')
require('./flash')
require('./pagination')
require('./filters')
require('./flatpickr')
require('cocoon-js-vanilla')
require('./views/locationEdit')
require('./duration_timer')
require('./reporting')
require('./sidebar')
require('./kpis')
require('./line_manager')
require('./collapsable_div')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
