document.addEventListener('turbolinks:load', () => {
  const node = document.querySelector('[data-location-edit-form]')
  if (node) {
    document.querySelectorAll('[data-station-location-select]').forEach((e) => {
      e.addEventListener('change', () => {
        node.dataset.confirm = node.dataset.confirmMessage
      })
    })
  }
})
